<template>
    <div
        class="threat-row"
    >
        <default-button
            flavor="link"
            size="lg"
            :block="true"
            @click.prevent.stop="active = !active"
        >
            <i class="las la-arrow-circle-right" aria-hidden="true"></i> <span class="mx-1 dot" :style="{ 'backgroundColor': row.color }"></span> {{ row.name }}: {{ row.count | nibnut.number("0,0") }}
        </default-button>
        <ul v-if="active" class="text-gray text-small">
            <li
                v-for="domain in row.domains"
                :key="domain.name"
            >
                <div class="columns">
                    <div class="column">{{ domain.name }}</div>
                    <div class="column col-auto">{{ domain.count | nibnut.number("0,0") }}</div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { DefaultButton } from "@/nibnut/components"

export default {
    name: "ThreatRow",
    components: {
        DefaultButton
    },
    props: {
        row: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            active: false
        }
    }
}
</script>
