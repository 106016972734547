var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"columns"},[(_vm.reportContent.dnsfilter.threats._total)?_c('div',{staticClass:"column col-sm-12"},[_c('h5',[_vm._v(" "+_vm._s(_vm.reportContent.dnsfilter.threats.stats.month)+" Top Cyber Threats Blocked "),_c('div',[(_vm.reportContent.dnsfilter.threats.stats.month_delta > 0)?_c('span',{staticClass:"text-small text-error"},[_c('i',{staticClass:"las la-long-arrow-alt-up"}),_vm._v("+"+_vm._s(_vm._f("nibnut.number")(_vm.reportContent.dnsfilter.threats.stats.month_delta,"0.00"))+"% ")]):(_vm.reportContent.dnsfilter.threats.stats.month_delta < 0)?_c('span',{staticClass:"text-small text-success"},[_c('i',{staticClass:"las la-long-arrow-alt-down"}),_vm._v(_vm._s(_vm._f("nibnut.number")(_vm.reportContent.dnsfilter.threats.stats.month_delta,"0.00"))+"% ")]):_c('span',{staticClass:"text-small text-gray"},[_vm._v("Unchanged")]),_c('span',{staticClass:"text-small text-gray"},[_vm._v("compared to last month")])])]),(_vm.reportContent.dnsfilter.threats.stats.ytd)?_c('h6',{staticClass:"text-gray"},[_vm._v("Cyber Threats Blocked This Year: "+_vm._s(_vm._f("nibnut.number")(_vm.reportContent.dnsfilter.threats.stats.ytd,"0,0")))]):_vm._e(),_c('apexchart',{attrs:{"id":"pie-dns-filter-threats","height":160,"type":"pie","options":_vm.chart_options('threats'),"series":_vm.chart_data('threats')}}),_c('quick-data-table',{attrs:{"id":"report-datatable-threats","column-definitions":_vm.columns_for('threats'),"all-rows":_vm.filtered_rows_for('threats'),"default-sort":{ sort_by: 'name', sort_dir: 'asc' }},scopedSlots:_vm._u([{key:"summary",fn:function(ref){
var row = ref.row;
return [_c('threat-row',{attrs:{"row":row}})]}},{key:"tbody",fn:function(ref){
var row = ref.row;
return [_c('threat-row',{attrs:{"row":row}})]}}],null,false,3501335745)})],1):_vm._e(),(_vm.reportContent.dnsfilter.sites._total)?_c('div',{staticClass:"column col-sm-12"},[_c('h5',[_vm._v(" "+_vm._s(_vm.reportContent.dnsfilter.threats.stats.month)+" Top Sites Blocked "),_c('div',[(_vm.reportContent.dnsfilter.sites.stats.month_delta > 0)?_c('span',{staticClass:"text-small text-error"},[_c('i',{staticClass:"las la-long-arrow-alt-up"}),_vm._v("+"+_vm._s(_vm._f("nibnut.number")(_vm.reportContent.dnsfilter.sites.stats.month_delta,"0.00"))+"% ")]):(_vm.reportContent.dnsfilter.sites.stats.month_delta < 0)?_c('span',{staticClass:"text-small text-success"},[_c('i',{staticClass:"las la-long-arrow-alt-down"}),_vm._v(_vm._s(_vm._f("nibnut.number")(_vm.reportContent.dnsfilter.sites.stats.month_delta,"0.00"))+"% ")]):_c('span',{staticClass:"text-small text-gray"},[_vm._v("Unchanged")]),_c('span',{staticClass:"text-small text-gray"},[_vm._v("compared to last month")])])]),(_vm.reportContent.dnsfilter.sites.stats.ytd)?_c('h6',{staticClass:"text-gray"},[_vm._v("Sites Blocked This Year: "+_vm._s(_vm._f("nibnut.number")(_vm.reportContent.dnsfilter.sites.stats.ytd,"0,0")))]):_vm._e(),_c('apexchart',{attrs:{"id":"pie-dns-filter-sites","height":160,"type":"pie","options":_vm.chart_options('sites'),"series":_vm.chart_data('sites')}}),_c('quick-data-table',{attrs:{"id":"report-datatable-sites","column-definitions":_vm.columns_for('sites'),"all-rows":_vm.filtered_rows_for('sites'),"default-sort":{ sort_by: 'name', sort_dir: 'asc' }},scopedSlots:_vm._u([{key:"summary",fn:function(ref){
var row = ref.row;
return [_c('threat-row',{attrs:{"row":row}})]}},{key:"tbody",fn:function(ref){
var row = ref.row;
return [_c('threat-row',{attrs:{"row":row}})]}}],null,false,3501335745)})],1):_vm._e()]),_c('div',{staticClass:"columns mt-4"},[_c('div',{staticClass:"column col-sm-12"},[_c('h5',[_vm._v("Whitelisted Domains")]),(!_vm.reportContent.dnsfilter.policies || !_vm.reportContent.dnsfilter.policies.whitelist || !_vm.reportContent.dnsfilter.policies.whitelist.length)?_c('div',{staticClass:"text-italic py-4"},[_vm._v(" No whitelisted domains ")]):_c('ul',_vm._l((_vm.reportContent.dnsfilter.policies.whitelist),function(domain){return _c('li',{key:domain},[_vm._v(" "+_vm._s(domain)+" ")])}),0)]),_c('div',{staticClass:"column col-sm-12"},[_c('h5',[_vm._v("Blacklisted Domains")]),(!_vm.reportContent.dnsfilter.policies || !_vm.reportContent.dnsfilter.policies.blacklist || !_vm.reportContent.dnsfilter.policies.blacklist.length)?_c('div',{staticClass:"text-italic py-4"},[_vm._v(" No blacklisted domains ")]):_c('ul',_vm._l((_vm.reportContent.dnsfilter.policies.blacklist),function(domain){return _c('li',{key:domain},[_vm._v(" "+_vm._s(domain)+" ")])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }