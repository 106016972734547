<template>
    <page
        id="report"
        :title="translate('Report')"
        :status="page_status"
        :waiting="waiting"
        :is-root="true"
        class="report-page"
        @statused="statused"
    >
        <div v-if="!!report" class="header">
            <div class="text-center">
                <app-logo />
                <h2 v-if="report.title">{{ report.title }}</h2>
                <br v-else />
                <a :href="`/report/${report.uuid}/pdf`" download class="hide-sm print-hide">
                    <i class="las la-file-download" aria-hidden="true"></i> Download PDF
                </a>
            </div>
            <div class="text-center mt-8">
                <div
                    v-if="report.logo_url"
                    class="nibnut-aspect-ratio-box nibnut-ratio-logo"
                >
                    <div>
                        <img :src="report.logo_url" class="img-responsive app-logo" />
                    </div>
                </div>
                <h1 v-else>{{ translate("Prepared For") }}:<br />{{ report.client_name }}</h1>
            </div>
            <div v-if="!!report" class="text-center mb-8">
                <span class="h2">{{ report.report_range }}</span>
                <div
                    v-if="!!report.history && (report.history.length > 1)"
                    class="popover popover-bottom text-left"
                >
                    <button type="button" class="btn btn-link">
                        <i class="las la-calendar" aria-hidden="true"></i>
                    </button>
                    <div class="popover-container text-small">
                        <ul class="menu">
                            <li
                                v-for="alternate_report in report.history"
                                :key="alternate_report.id"
                                class="menu-item"
                            >
                                <a
                                    :href="`/report/${alternate_report.id}`"
                                >
                                    {{ alternate_report.name }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <ul class="toc">
                <li
                    v-for="section in report.sections"
                    :key="section.id"
                    class="toc-item"
                >
                    <a
                        :href="`#${section.id}`"
                        @click.prevent.stop="scroll_to(section.id)"
                    >
                        <i
                            :class="`las la-${section.glyph}`"
                            aria-hidden="true"
                        ></i> {{ section.title }}</a>
                </li>
            </ul>
        </div>
        <div v-if="!!report" class="body">
            <div v-if="report.note_content" class="card full-width mb-8">
                <div class="card-header" v-html="report.note_content"></div>
            </div>
            <section
                v-for="section in report.sections"
                :key="section.id"
                :id="section.id"
            >
                <div class="card full-width">
                    <div class="card-header">
                        <div class="card-title h5">
                            <i
                                :class="`las la-${section.glyph}`"
                                aria-hidden="true"
                            ></i> {{ section.title }}
                            <div v-if="section.description" class="nibnut-help-button popover">
                                <button class="btn btn-link btn-md btn-regular">
                                    <i class="las la-question-circle" aria-hidden="true"></i>
                                </button>
                                <div class="popover-container">
                                    <div class="card">
                                        <div class="card-body" v-html="section.description"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div v-if="section._special_message" class="p-8 text-center h4" v-html="section._special_message"></div>
                        <support-panel
                            v-else-if="section.id === 'support'"
                            :report-content="report.content"
                        />
                        <computers-panel
                            v-else-if="section.id === 'computers'"
                            :report-content="report.content"
                        />
                        <network-panel
                            v-else-if="section.id === 'network'"
                            :report-content="report.content"
                            :report-month="report.report_month"
                        />
                        <threats-panel
                            v-else-if="section.id === 'threats'"
                            :report-content="report.content"
                        />
                        <emails-panel
                            v-else-if="section.id === 'emails'"
                            :report-content="report.content"
                        />
                    </div>
                </div>
            </section>
        </div>
        <div v-if="!!report" class="footer text-small">
            <div v-if="report.footer" class="mb-8" v-html="report.footer"></div>
            &copy;&nbsp;{{ current_year }}&nbsp;{{ setting("customer_name") }}<br />
        </div>
    </page>
</template>

<script type="text/javascript">
import { is_page, string_utilities, misc_utilities, profile_utilities } from "@/nibnut/mixins"

import { AppLogo } from "@/custom/components"
import SupportPanel from "./SupportPanel"
import ComputersPanel from "./ComputersPanel"
import NetworkPanel from "./NetworkPanel"
import ThreatsPanel from "./ThreatsPanel"
import EmailsPanel from "./EmailsPanel"

export default {
    mixins: [is_page, string_utilities, misc_utilities, profile_utilities],
    components: {
        AppLogo,
        SupportPanel,
        ComputersPanel,
        NetworkPanel,
        ThreatsPanel,
        EmailsPanel
    },
    mounted () {
        this.load()
    },
    watch: {
        $route: "load"
    },
    methods: {
        load () {
            this.$store.dispatch(
                "FETCH_RECORD",
                {
                    entity: "report",
                    id: this.$route.params.id,
                    query: { fields: ["fieldset::view"] },
                    anonymous: true
                }
            ).then(record => {
                this.report = record
                setTimeout(() => {
                    if(document.location.hash) {
                        const link = document.querySelector("a[href=\"" + document.location.hash + "\"]")
                        if(link) link.click()
                    }
                }, 100)
            }).catch(error => {
                this.$error(error.message)
            })
        },
        scroll_to (section_id) {
            const hash = `#${section_id}`
            const node = document.querySelector(hash)
            if(node) {
                node.scrollIntoView({
                    behavior: "smooth",
                    block: "start"
                })
                history.pushState(null, null, hash)
            }
        }
    },
    data () {
        return {
            report: null
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/app";

.nibnut-app > .report-page {
    background-color: $gray-color-light;

    #report {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        max-width: 960px;
        margin: $unit-8 auto;

        & > h1 {
            display: none;
        }
        & > div.header {
            flex: 0 0 100%;
            padding: $layout-spacing-lg;

            img.img-responsive.app-logo {
                display: inline-block;
                height: 2.4rem;
                margin: $layout-spacing-lg 0;
            }
            & > .columns {
                margin-top: $unit-16;
                .report-range {
                    text-align: right;
                    @media (max-width: $size-sm) {
                        text-align: center;
                    }
                }
            }
            @media (max-width: $size-sm) {
                & > div { text-align: center; }
            }
        }
        & > div.body {
            flex: 1 0 auto;
            padding: $layout-spacing-lg;

            section {
                margin: $unit-4 0;

                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .line-graph {
                height: 160px;
            }
        }
        & > div.footer {
            flex: 0 0 100%;
            padding: $layout-spacing-lg;
            text-align: center;
        }
    }
    .quick-stat {
        font-size: 5rem;
        text-align: center;
        & > div {
            font-size: $font-size;
            color: $gray-color;
        }
    }
    ul.horizontal-list {
        list-style: none;
        margin: $unit-1 0;

        & > li {
            display: inline-block;
            margin: 0;

            &:not(:last-child) {
                margin-right: $unit-1;
            }

            &:not(:first-child) {
                &::before {
                    color: $gray-color-dark;
                    content: "|";
                    padding-right: $unit-2;
                }
            }
        }
    }
    table.table {
        td, th {
            vertical-align: top
        }
        & > tbody.show-all {
            display: none;
        }
        &.show-all {
            & > tbody.show-all {
                display: table-row-group;
            }
            & > tfoot {
                display: none;
            }
        }
        &.expandable-table {
            & > tbody {
                .btn.expand-row {
                    text-align: left;
                    border: 0;
                    height: auto;
                    line-height: normal;
                    padding: .25rem 0;

                    & > i {
                        transition: transform  0.3s ease-in-out;
                    }
                }

                &.expansion {
                    display: none;
                    /*td, th {
                        border: 0;
                        padding: .3rem .4rem;
                    }*/
                }

                &.expanded {
                    .btn.expand-row {
                        & > i {
                            transform: rotate(90deg);
                        }
                    }

                    & + .expansion {
                        display: table-row-group;
                    }
                }
            }
        }
        @media (max-width: $size-sm) {
            display: block;
            overflow-x: auto;
            padding-bottom: .75rem;
            white-space: nowrap;
        }
    }
    .toc {
        list-style: none;
        margin: $unit-1 0;
        padding: $unit-1 0;
        flex: 1 0 auto;

        .toc-item {
            display: inline-block;
            margin: 0;
            padding: $unit-1 0;

            &:not(:last-child) {
                margin-right: $unit-1;
            }

            &:not(:first-child) {
                &::before {
                    color: $gray-color-dark;
                    content: "|";
                    padding-right: $unit-2;
                }
            }
        }
        @media (max-width: $size-sm) {
            display: block;
            overflow-x: auto;
            padding-bottom: .75rem;
            flex: 1 1 auto;
        }
    }
    .calendar-grid {
        & > .calendar-grid-week {
            display: flex;
            justify-content: center;

            .calendar-grid-day {
                flex: 0 0 auto;
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 4px;
                margin: 2px;

                &.calendar-grid-day-dark {
                    background-color: $brand-color;
                }
                &.calendar-grid-day-medium {
                    background-color: rgba($brand-color, 0.8);
                }
                &.calendar-grid-day-light {
                    background-color: rgba($brand-color, 0.5);
                }

                @media (max-width: $size-sm) {
                    position: static;
                    top: auto;
                    left: auto;
                    transform: none;
                }
            }
        }
    }
}
@media print {
    .nibnut-app > .report-page {
        background-color: white;
        div.body {
            section {
                break-before: always;
                &:first-child {
                    break-before: auto;
                }
            }
        }
        table.table {
            break-inside: auto;

            & > thead {
                display: table-row-group;
                th {
                    position: static;
                    top: 0;
                }
            }
            & > tbody.show-all {
                display: table-row-group;
            }
            & > tfoot {
                display: none;
            }
            &.show-all {
                & > tfoot {
                    display: none;
                }
            }
            &.expandable-table {
                & > tbody {
                    .btn.expand-row {
                        & > i {
                            display: none;
                        }
                    }

                    &.expansion {
                        display: table-row-group;
                    }
                }
            }
        }
        .toc {
            display: none;
        }
    }
}
</style>
