<template>
    <div>
        <div class="columns mb-4">
            <div class="column">
                <div class="quick-stat">
                    <h6><span class="mx-1 dot" style="background-color: #2CB0DC;"></span>Spam Blocked</h6>
                    {{ reportContent.zerospam.spam.month | nibnut.number("0,0") }}
                    <h6>
                        <span v-if="reportContent.zerospam.spam.month_delta > 0" class="text-small text-error">
                            <i class="las la-long-arrow-alt-up"></i>+{{ reportContent.zerospam.spam.month_delta | nibnut.number("0.00") }}%
                        </span>
                        <span v-else-if="reportContent.zerospam.spam.month_delta < 0" class="text-small text-success">
                            <i class="las la-long-arrow-alt-down"></i>{{ reportContent.zerospam.spam.month_delta | nibnut.number("0.00") }}%
                        </span>
                        <span v-else class="text-small text-gray">Unchanged&nbsp;</span>
                        <span class="text-small text-gray">compared to last month</span>
                    </h6>
                    <div>This year: {{ reportContent.zerospam.spam.ytd | nibnut.number("0,0") }}</div>
                </div>
            </div>
            <div class="column">
                <div class="quick-stat">
                    <h6><span class="mx-1 dot" style="background-color: #C642DB;"></span>Virus Blocked</h6>
                    {{ reportContent.zerospam.threat.month | nibnut.number("0,0") }}
                    <h6>
                        <span v-if="reportContent.zerospam.threat.month_delta > 0" class="text-small text-error">
                            <i class="las la-long-arrow-alt-up"></i>+{{ reportContent.zerospam.threat.month_delta | nibnut.number("0.00") }}%
                        </span>
                        <span v-else-if="reportContent.zerospam.threat.month_delta < 0" class="text-small text-success">
                            <i class="las la-long-arrow-alt-down"></i>{{ reportContent.zerospam.threat.month_delta | nibnut.number("0.00") }}%
                        </span>
                        <span v-else class="text-small text-gray">Unchanged&nbsp;</span>
                        <span class="text-small text-gray">compared to last month</span>
                    </h6>
                    <div>This year: {{ reportContent.zerospam.threat.ytd | nibnut.number("0,0") }}</div>
                </div>
            </div>
        </div>
        <apexchart
            id="bar-zerospam"
            :height="160"
            type="bar"
            :options="chart_options"
            :series="chart_data"
        />
    </div>
</template>

<script>
import is_report_panel from "./is_report_panel"

export default {
    name: "EmailsPanel",
    mixins: [is_report_panel],
    computed: {
        chart_options () {
            const nb_ticks = Math.min(6, Math.max(...this.reportContent.zerospam.graph_data.series[0].data, ...this.reportContent.zerospam.graph_data.series[1].data))
            return {
                chart: {
                    toolbar: { show: false }
                },
                grid: {
                    xaxis: { lines: { show: false } }
                },
                dataLabels: { enabled: false },
                xaxis: {
                    type: "dateTime",
                    tickAmount: 15,
                    categories: this.reportContent.zerospam.graph_data.categories
                },
                yaxis: {
                    tickAmount: nb_ticks,
                    labels: {
                        formatter: (value) => {
                            return value.toFixed(0)
                        }
                    }
                }
            }
        },
        chart_data () {
            return this.reportContent.zerospam.graph_data.series
        }
    }
}
</script>
