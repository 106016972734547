<template>
    <div>
        <data-table
            id="report-datatable-support"
            :columns="columnDefinitions"
            :rows="filtered_rows"
            :search="state.search"
            :searchable="searchable"
            :total="allRows.length"
            :found="allRows.length"
            :current-page="1"
            :total-pages="1"
            :can-add="false"
            :show-totals="false"
            :show-head="showHead"
            class="non-sticky"
            @sort="sort_by"
            @search="search"
            @click="$emit('click', $event)"
        >
            <template v-slot:controls>
                <div v-if="!!filterOn && !!filters.length">
                    <form-dropdown
                        id="filter"
                        name="filter"
                        :value="state.filter"
                        empty-value=""
                        :options="filters"
                        :required="true"
                        @input="filter(filterOn, $event)"
                    />
                </div>
            </template>
            <template v-slot:summary="{ row }">
                <slot name="summary" :row="row"></slot>
            </template>
            <template v-slot:tbody="{ row, field }">
                <slot name="tbody" :row="row" :field="field"></slot>
            </template>
        </data-table>
        <default-button
            v-if="!!state.per_page && (state.found > state.per_page)"
            flavor="link"
            :block="true"
            @click.prevent.stop="set_page_size(0)"
        >
            Show All...
        </default-button>
    </div>
</template>

<script>
import { FormDropdown, DefaultButton } from "@/nibnut/components"

import is_data_table_source from "@/nibnut/mixins/IsDataTableSource"

export default {
    name: "QuickDataTable",
    mixins: [is_data_table_source],
    components: {
        FormDropdown,
        DefaultButton
    },
    mounted () {
        this.resort()
    },
    watch: {
        defaultSort: "resort"
    },
    methods: {
        resort () {
            this.set_state_values(this.defaultSort)
            this.refresh()
        }
    },
    computed: {
        state_identifier () {
            return this.id
        },
        rows () {
            return this.allRows
        }
    },
    props: {
        id: {
            type: String,
            required: true
        },
        columnDefinitions: {
            type: Object,
            required: true
        },
        allRows: {
            type: Array,
            required: true
        },
        defaultSort: {
            type: Object,
            default () {
                return {
                    sort_by: null,
                    sort_dir: null
                }
            }
        },
        searchable: {
            type: Boolean,
            default: false
        },
        showHead: {
            type: Boolean,
            default: true
        },
        filterOn: {
            type: String,
            default: ""
        },
        filters: {
            type: Array,
            default () {
                return []
            }
        }
    },
    data () {
        return {
            default_state: {
                per_page: 5,
                page: 1,
                sort_by: "filed_at",
                sort_dir: "desc",
                filter_on: null,
                filter: "",
                archived: false,
                search: "",
                total: 0,
                found: 0
            }

        }
    }
}
</script>
