<template>
    <div>
        <div class="columns">
            <div class="column col-sm-12">
                <h6 v-if="reportMonth" class="text-center text-gray">{{ reportMonth }}</h6>
                <div class="calendar-grid">
                    <div
                        v-for="(week, index) in reportContent.uptimerobot.rows"
                        :key="index"
                        class="calendar-grid-week"
                    >
                        <div
                            v-for="day in week"
                            :key="id_for_day(day)"
                            :class="{ 'tooltip': !!day, [`calendar-grid-day-${ (!day ? 'empty' : (day.uptime < 98) ? 'light': ((day.uptime <= 99.5) ? 'medium' : 'dark')) }`]: true }"
                            :data-tooltip="tooltip_for_day(day)"
                            class="calendar-grid-day"
                        >
                            &nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <div class="column col-sm-6">
                <div class="quick-stat">
                    {{ reportContent.uptimerobot.stats.interruptions | nibnut.number("0,0") }}
                    <div>interruptions</div>
                </div>
            </div>
            <div class="column col-sm-6">
                <div class="quick-stat">
                    {{ reportContent.uptimerobot.stats.downtime | nibnut.number("0,0") }}
                    <div>minutes downtime</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import is_report_panel from "./is_report_panel"

let empty_day_id = 0

export default {
    name: "NetworkPanel",
    mixins: [is_report_panel],
    methods: {
        id_for_day (day) {
            if(!day) return `empty-day-${empty_day_id++}`
            return day.id
        },
        tooltip_for_day (day) {
            if(!day) return false
            return [
                `Date: ${day.date}`,
                `${day.interruptions} interruptions`,
                `${day.downtime} minutes downtime`,
                `${day.uptime}% uptime`
            ].join("\n")
        }
    },
    props: {
        reportMonth: {
            type: String,
            default: ""
        }
    }
}
</script>
