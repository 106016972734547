<template>
    <div>
        <apexchart
            id="bar-zendesk"
            :height="160"
            type="bar"
            :options="chart_options"
            :series="chart_data"
        />
        <div class="columns">
            <div class="column col-6 col-sm-12">
                <h5>
                    {{ reportContent.zendesk.stats.month }}
                    Support Tickets
                    <div>
                        <span
                            v-if="reportContent.zendesk.stats.month_delta > 0"
                            class="text-small text-error"
                        >
                            <i class="las la-long-arrow-alt-up"></i>+{{ reportContent.zendesk.stats.month_delta | nibnut.number("0,0.00") }}%
                        </span>
                        <span
                            v-else-if="reportContent.zendesk.stats.month_delta < 0"
                            class="text-small text-success"
                        >
                            <i class="las la-long-arrow-alt-down"></i>{{ reportContent.zendesk.stats.month_delta | nibnut.number("0,0.00") }}%
                        </span>
                        <span
                            v-else
                            class="text-small text-gray"
                        >
                            Unchanged&nbsp;
                        </span>
                        <span class="text-small text-gray">compared to last month</span>
                    </div>
                </h5>
                <h6 class="text-gray">Support Tickets This Year: {{ reportContent.zendesk.stats.ytd | nibnut.number("0,0") }}</h6>
            </div>
            <div class="column col-6 col-sm-12 text-right">
                <h5>
                    {{ reportContent.zendesk.stats.avg_response | nibnut.number("0,0") }} min Average Response Time
                    <div>
                        <span
                            v-if="reportContent.zendesk.stats.avg_response_delta > 0"
                            class="text-small text-error"
                        >
                            <i class="las la-long-arrow-alt-up"></i>+{{ reportContent.zendesk.stats.avg_response_delta | nibnut.number("0,0.00") }}%
                        </span>
                        <span
                            v-else-if="reportContent.zendesk.stats.avg_response_delta < 0"
                            class="text-small text-success"
                        >
                            <i class="las la-long-arrow-alt-down"></i>{{ reportContent.zendesk.stats.avg_response_delta | nibnut.number("0,0.00") }}%
                        </span>
                        <span
                            v-else
                            class="text-small text-gray"
                        >
                            Unchanged&nbsp;
                        </span>
                        <span class="text-small text-gray">compared to last month</span>
                    </div>
                </h5>
                <h6 class="text-gray">Average Response Time This Year: {{ reportContent.zendesk.stats.avg_response_ytd | nibnut.number("0,0") }}</h6>
            </div>
        </div>
        <quick-data-table
            id="report-datatable-support"
            :column-definitions="columns"
            :all-rows="rows"
            :default-sort="{ sort_by: 'filed_at', sort_dir: 'desc' }"
            :searchable="true"
            filter-on="status"
            :filters="statuses"
            class="mt-6"
            @click="proxy_click"
        >
            <template
                v-slot:summary="{ row }"
            >
                <h6>{{ row.subject }}</h6>
                <p class="text-gray text-small">
                    {{ row.filed_at | nibnut.date("MM-dd") }}
                    &nbsp;&bull;&nbsp;
                    {{ row.requested_by }}
                </p>
                <p>
                    <span>
                        <span v-if="!!row.status && !!row.status.match(/^closed/i)">Resolved</span>
                        <span v-else-if="!!row.status">{{ row.status.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) }}</span>
                    </span>
                    &nbsp;&bull;&nbsp;
                    Response time: {{ row.response_time | nibnut.number("0,0") }} min
                </p>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <span v-if="field === 'filed_at'">{{ row.filed_at | nibnut.date("MM-dd") }}</span>
                <span v-else-if="field === 'response_time'">{{ row.response_time | nibnut.number("0,0") }} min</span>
                <span v-else-if="field === 'status'">
                    <span v-if="!!row.status && !!row.status.match(/^closed/i)">Resolved</span>
                    <span v-else-if="!!row.status">{{ row.status.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) }}</span>
                </span>
                <a
                    v-else-if="field === 'subject'"
                    :id="node_id_for_ticket(row)"
                    :href="zendesk_url_for_ticket(row)"
                    target="_blank"
                    @click.stop
                >
                    {{ row.subject }}
                </a>
                <span v-else>{{ row[field] }}</span>
            </template>
        </quick-data-table>
    </div>
</template>

<script>
import orderBy from "lodash/orderBy"

import { profile_utilities } from "@/nibnut/mixins"
import is_report_panel from "./is_report_panel"

export default {
    name: "SupportPanel",
    mixins: [profile_utilities, is_report_panel],
    methods: {
        node_id_for_ticket (ticket) {
            return `zendesk-ticket-${ticket.id}`
        },
        zendesk_url_for_ticket (ticket) {
            if(!this.profile_id) return `https://helpdesk.supportmymac.ca/hc/en-us/requests/${ticket.remote_id}`
            return `https://Supportmymac.zendesk.com/agent/tickets/${ticket.remote_id}`
        },
        proxy_click (ticket) {
            if(ticket) {
                const id = this.node_id_for_ticket(ticket)
                if(id) {
                    const node = document.getElementById(id)
                    if(node) node.click()
                }
            }
        }
    },
    computed: {
        chart_options () {
            const nb_ticks = Math.min(6, Math.max(...this.reportContent.zendesk.graph_data.series[0].data))
            return {
                chart: {
                    toolbar: { show: false }
                },
                grid: {
                    xaxis: { lines: { show: false } }
                },
                dataLabels: { enabled: false },
                xaxis: {
                    type: "dateTime",
                    tickAmount: 15,
                    categories: this.reportContent.zendesk.graph_data.categories
                },
                yaxis: {
                    tickAmount: nb_ticks,
                    labels: {
                        formatter: (value) => {
                            return value.toFixed(0)
                        }
                    }
                }
            }
        },
        chart_data () {
            return this.reportContent.zendesk.graph_data.series
        },
        rows () {
            return this.reportContent.zendesk.rows
        },
        statuses () {
            let statuses = {}
            this.reportContent.zendesk.rows.forEach(row => {
                const id = row.status
                if(!statuses[id]) {
                    const name = row.status.match(/^closed/i) ? "Resolved" : row.status.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                    statuses[id] = { id, name }
                }
            })
            statuses = orderBy(Object.values(statuses), "name", "asc")
            statuses.unshift({ id: null, name: "Any Status" })
            return statuses
        }
    },
    data () {
        return {
            columns: {
                filed_at: { label: "Date", sort: "desc", type: "numeric" },
                requested_by: { label: "Requester Name", sort: null, type: "alpha", head_class: "no-wrap", cell_class: "no-wrap" },
                subject: { label: "Subject", sort: null, type: "alpha" },
                response_time: { label: "Response Time", sort: null, type: "numeric" },
                status: { label: "Status", sort: false }
            }
        }
    }
}
</script>
