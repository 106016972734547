<template>
    <div>
        <div class="columns">
            <div v-if="reportContent.dnsfilter.threats._total" class="column col-sm-12">
                <h5>
                    {{ reportContent.dnsfilter.threats.stats.month }}
                    Top Cyber Threats Blocked
                    <div>
                        <span v-if="reportContent.dnsfilter.threats.stats.month_delta > 0" class="text-small text-error">
                            <i class="las la-long-arrow-alt-up"></i>+{{ reportContent.dnsfilter.threats.stats.month_delta | nibnut.number("0.00") }}%
                        </span>
                        <span v-else-if="reportContent.dnsfilter.threats.stats.month_delta < 0" class="text-small text-success">
                            <i class="las la-long-arrow-alt-down"></i>{{ reportContent.dnsfilter.threats.stats.month_delta | nibnut.number("0.00") }}%
                        </span>
                        <span v-else class="text-small text-gray">Unchanged</span>
                        <span class="text-small text-gray">compared to last month</span>
                    </div>
                </h5>
                <h6 v-if="reportContent.dnsfilter.threats.stats.ytd" class="text-gray">Cyber Threats Blocked This Year: {{ reportContent.dnsfilter.threats.stats.ytd | nibnut.number("0,0") }}</h6>

                <apexchart
                    id="pie-dns-filter-threats"
                    :height="160"
                    type="pie"
                    :options="chart_options('threats')"
                    :series="chart_data('threats')"
                />
                <quick-data-table
                    id="report-datatable-threats"
                    :column-definitions="columns_for('threats')"
                    :all-rows="filtered_rows_for('threats')"
                    :default-sort="{ sort_by: 'name', sort_dir: 'asc' }"
                >
                    <template
                        v-slot:summary="{ row }"
                    >
                        <threat-row
                            :row="row"
                        />
                    </template>
                    <template
                        v-slot:tbody="{ row }"
                    >
                        <threat-row
                            :row="row"
                        />
                    </template>
                </quick-data-table>
            </div>
            <div v-if="reportContent.dnsfilter.sites._total" class="column col-sm-12">
                <h5>
                    {{ reportContent.dnsfilter.threats.stats.month }}
                    Top Sites Blocked
                    <div>
                        <span v-if="reportContent.dnsfilter.sites.stats.month_delta > 0" class="text-small text-error">
                            <i class="las la-long-arrow-alt-up"></i>+{{ reportContent.dnsfilter.sites.stats.month_delta | nibnut.number("0.00") }}%
                        </span>
                        <span v-else-if="reportContent.dnsfilter.sites.stats.month_delta < 0" class="text-small text-success">
                            <i class="las la-long-arrow-alt-down"></i>{{ reportContent.dnsfilter.sites.stats.month_delta | nibnut.number("0.00") }}%
                        </span>
                        <span v-else class="text-small text-gray">Unchanged</span>
                        <span class="text-small text-gray">compared to last month</span>
                    </div>
                </h5>
                <h6 v-if="reportContent.dnsfilter.sites.stats.ytd" class="text-gray">Sites Blocked This Year: {{ reportContent.dnsfilter.sites.stats.ytd | nibnut.number("0,0") }}</h6>

                <apexchart
                    id="pie-dns-filter-sites"
                    :height="160"
                    type="pie"
                    :options="chart_options('sites')"
                    :series="chart_data('sites')"
                />
                <quick-data-table
                    id="report-datatable-sites"
                    :column-definitions="columns_for('sites')"
                    :all-rows="filtered_rows_for('sites')"
                    :default-sort="{ sort_by: 'name', sort_dir: 'asc' }"
                >
                    <template
                        v-slot:summary="{ row }"
                    >
                        <threat-row
                            :row="row"
                        />
                    </template>
                    <template
                        v-slot:tbody="{ row }"
                    >
                        <threat-row
                            :row="row"
                        />
                    </template>
                </quick-data-table>
            </div>
        </div>
        <div class="columns mt-4">
            <div class="column col-sm-12">
                <h5>Whitelisted Domains</h5>
                <div
                    v-if="!reportContent.dnsfilter.policies || !reportContent.dnsfilter.policies.whitelist || !reportContent.dnsfilter.policies.whitelist.length"
                    class="text-italic py-4"
                >
                    No whitelisted domains
                </div>
                <ul v-else>
                    <li
                        v-for="domain in reportContent.dnsfilter.policies.whitelist"
                        :key="domain"
                    >
                        {{ domain }}
                    </li>
                </ul>
            </div>
            <div class="column col-sm-12">
                <h5>Blacklisted Domains</h5>
                <div
                    v-if="!reportContent.dnsfilter.policies || !reportContent.dnsfilter.policies.blacklist || !reportContent.dnsfilter.policies.blacklist.length"
                    class="text-italic py-4"
                >
                    No blacklisted domains
                </div>
                <ul v-else>
                    <li
                        v-for="domain in reportContent.dnsfilter.policies.blacklist"
                        :key="domain"
                    >
                        {{ domain }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import is_report_panel from "./is_report_panel"

import ThreatRow from "./ThreatRow"

export default {
    name: "ThreatsPanel",
    mixins: [is_report_panel],
    components: {
        ThreatRow
    },
    methods: {
        columns_for (id) {
            return {
                name: { label: "Item", sort: false }
            }
        },
        filtered_rows_for (id) {
            return this.reportContent.dnsfilter[id].rows
        },
        chart_options (id) {
            return {
                chart: {
                    toolbar: { show: false }
                },
                legend: {
                    show: false
                },
                labels: this.reportContent.dnsfilter[id].graph_data.categories,
                dataLabels: { enabled: true },
                tooltip: {
                    y: {
                        formatter: (value) => {
                            return value.toFixed(2)
                        }
                    }
                }
            }
        },
        chart_data (id) {
            return this.reportContent.dnsfilter[id].graph_data.series
        }
    }
}
</script>
