import QuickDataTable from "./QuickDataTable"

import VueApexCharts from "vue-apexcharts"
window.Apex.colors = [
    "#2CB0DC", "#C642DB", "#DB7A16", "#84DB21", "#375CDB",
    "#DB4269", "#8F8715", "#71925F", "#D486B2", "#765F9B",
    "#F4C550", "#9C3821", "#718C9C", "#9A5B42", "#8A776D",
    "#692B56", "#CEC842", "#DBA716", "#4D494A", "#000000"
]

const is_report_panel = {
    components: {
        QuickDataTable,
        Apexchart: VueApexCharts
    },
    props: {
        reportContent: {
            type: Object,
            required: true
        }
    }
}

export default is_report_panel
